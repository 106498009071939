import React from 'react';

const RecentTickets: React.FC = () => {
  const tickets = [
    { id: 1, client: 'John Doe', subject: 'Problème de connexion', status: 'Ouvert' },
    { id: 2, client: 'Jane Smith', subject: 'Facturation incorrecte', status: 'Résolu' },
    { id: 3, client: 'Alice Johnson', subject: 'Demande de fonctionnalité', status: 'En cours' },
  ];

  return (
    <div className="bg-purple-800 p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4 text-gray-800">Tickets Récents</h2>
      <table className="min-w-full">
        <thead>
          <tr className="bg-gray-50">
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">ID</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Client</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Sujet</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Statut</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket) => (
            <tr key={ticket.id} className="border-b">
              <td className="px-6 py-4 text-sm text-gray-900">{ticket.id}</td>
              <td className="px-6 py-4 text-sm text-gray-900">{ticket.client}</td>
              <td className="px-6 py-4 text-sm text-gray-900">{ticket.subject}</td>
              <td className="px-6 py-4 text-sm text-gray-900">
                <span className={`px-2 py-1 rounded-full text-xs ${
                  ticket.status === 'Ouvert' ? 'bg-red-100 text-red-800' :
                  ticket.status === 'Résolu' ? 'bg-green-100 text-green-800' :
                  'bg-yellow-100 text-yellow-800'
                }`}>
                  {ticket.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RecentTickets;