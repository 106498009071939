import React, { useState, useEffect } from "react";
import { ChevronDown, ChevronUp, Copy } from "lucide-react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/collapsible";
import { useTranslation } from "react-i18next";
import { Orders } from "@/types/types";
import { cn } from "../../utils/utils";
import ConfirmQrDialog from "../Dialogs/ConfirmaQrDialog";

interface TransactionHistoryProps {
  orders: Orders[];
  qrCode: string;
  isLoading: boolean;
  onSelectPhone?: (phone: string) => void;
}

const statusColors = {
  success: "text-green-500 bg-green-500/10",
  pending: "text-yellow-500 bg-yellow-500/10",
  failed: "text-red-500 bg-red-500/10",
};

const formatDate = (dateString?: string) => {
  if (!dateString) return "Date invalide";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.error("Date invalide :", dateString);
    return "Date invalide";
  }

  const formattedDate = date.toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${formattedDate}, ${formattedTime}`;
};

const TransactionHistory: React.FC<TransactionHistoryProps> = ({
  orders,
  isLoading,
  onSelectPhone,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [showQr, setShowQr] = useState(false);
  const [customData, setCustomData] = useState<any | null>(null);
  const [qrCodeImg, setQrCodeImg] = useState<string | null>(null);
  const [lien, setLien] = useState<string>("");
  const [fromCurrency, setFromCurrency] = useState<string>("");
  const { t } = useTranslation();

  const getTransactionStatus = (order: Orders) => {
    try {
      if (!order?.transactions?.[0] || !order?.transactions?.[1]) {
        return { text: t("failed"), style: statusColors.failed };
      }

      if (
        order.status === "failed" ||
        (order.transactions[0].status === "failed" &&
          order.transactions[1].status === "failed")
      ) {
        return { text: t("failed"), style: statusColors.failed };
      }

      if (
        order.status === "success" ||
        (order.transactions[0].status === "success" &&
          order.transactions[1].status === "success")
      ) {
        return { text: t("success"), style: statusColors.success };
      }

      if (order.transactions[0].status === "pending") {
        return { text: t("retraitPending"), style: statusColors.pending };
      }
      if (order.transactions[0].status === "failed") {
        return { text: t("retraitFailed"), style: statusColors.failed };
      }

      if (
        order.transactions[0].status === "success" &&
        order.transactions[1].status === "pending"
      ) {
        return { text: t("depotPending"), style: statusColors.pending };
      }

      if (
        order.transactions[0].status === "success" &&
        order.transactions[1].status === "failed"
      ) {
        return { text: t("depotFailed"), style: statusColors.failed };
      }

      return { text: t("pending"), style: statusColors.pending };
    } catch (error) {
      console.error("Error getting transaction status:", error);
      return { text: t("failed"), style: statusColors.failed };
    }
  };

  const handleCopy = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  const handlePhoneClick = (phone: string) => {
    if (onSelectPhone) {
      onSelectPhone(phone);
      setIsOpen(false);
    }
  };

  const parseTransactionResponse = (response: string) => {
    try {
      if (!response) return null;
      const dataResponse = JSON.parse(response);
      if (!dataResponse?.transaction?.custom_data) return null;
      return JSON.parse(dataResponse.transaction.custom_data);
    } catch (error) {
      console.error("Error parsing transaction response:", error);
      return null;
    }
  };

  const handleShowQr = (order: Orders) => {
    try {
      if (!order?.transactions?.[0]?.response) {
        console.warn("Transaction response is missing");
        return;
      }

      const customData = parseTransactionResponse(
        order.transactions[0].response
      );
      if (!customData?.qrCodeImg || !customData?.wave_launch_url) {
        console.warn("QR code data is incomplete");
        return;
      }

      setQrCodeImg(customData.qrCodeImg);
      setLien(customData.wave_launch_url);
      setShowQr(true);
      setFromCurrency(order.transactions[0].currency);
    } catch (error) {
      console.error("Error processing QR code data:", error);
    }
  };

  const handleShowBtn = (order: Orders) => {
    try {
      const customData = parseTransactionResponse(
        order?.transactions?.[0]?.response
      );
      if (customData) {
        setCustomData(customData);
      }
    } catch (error) {
      console.error("Error processing custom data:", error);
    }
  };

  const renderQrButton = (order: Orders) => {
    try {
      if (
        !order?.transactions?.[0]?.response ||
        order.transactions[0].status !== "pending"
      ) {
        return null;
      }

      const customData = parseTransactionResponse(
        order.transactions[0].response
      );
      if (!customData?.qrCodeImg) {
        return null;
      }

      return (
        <button
          onClick={() => handleShowQr(order)}
          className="px-2 py-1 text-xs sm:text-sm md:px-3 text-white bg-purple-900 hover:bg-purple-700 rounded-lg whitespace-nowrap"
        >
          Voir QR
        </button>
      );
    } catch (error) {
      console.error("Error rendering QR button:", error);
      return null;
    }
  };

  useEffect(() => {
    if (orders?.length > 0) {
      orders.forEach((order) => {
        if (order) handleShowBtn(order);
      });
    }
  }, [orders]);

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="overflow-hidden"
    >
      <CollapsibleTrigger className="w-full px-4 py-3 gap-2 flex items-center justify-center text-white">
        <div className="flex items-center gap-1">
          <span className="font-medium text-lg">{t("titreHistory")}</span>
          <span className="text-xs text-white/70">({orders?.length || 0})</span>
        </div>
        {isOpen ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
      </CollapsibleTrigger>

      <CollapsibleContent>
        <div className="max-h-[50vh] overflow-y-auto px-2 text-white space-y-2">
          {orders?.map((order) => {
            if (!order?.transactions?.[0] || !order?.transactions?.[1])
              return null;
            const status = getTransactionStatus(order);

            return (
              <div
                key={order.id}
                className="p-3 mx-1 my-1 bg-white/5 rounded-lg hover:bg-white/10 transition-colors"
              >
                <div className="flex justify-between">
                  <div className="space-x-2">
                    <span>
                      {order.transactions[0].currency} →{" "}
                      {order.transactions[1].currency}
                    </span>
                  </div>
                  <div>
                    <span
                      className={cn(
                        "text-xs px-2 py-0.5 rounded-full",
                        status.style
                      )}
                    >
                      {status.text}
                    </span>
                  </div>
                  <div>
                    <div className="text-xs">
                      {order.transactions[0].amount?.toLocaleString()} FCFA →
                    </div>
                    <div className="text-xs text-white/70">
                      {order.transactions[1].amount?.toLocaleString()} FCFA
                    </div>
                  </div>
                </div>
                <div className="text-sm text-white/70">
                  {formatDate(order.createdAt)}
                </div>
                <div className="flex justify-between">
                  <div className="text-sm flex items-center gap-2 mt-1">
                    <span className="text-white/60">{t("de")}</span>
                    <button
                      onClick={() =>
                        handlePhoneClick(
                          order.transactions[0].phoneNumber?.toString() || ""
                        )
                      }
                      className="hover:text-white/90 transition-colors flex items-center gap-1"
                    >
                      {order.transactions[0].phoneNumber}
                      <Copy
                        size={14}
                        className="opacity-50 hover:opacity-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopy(
                            order.transactions[0].phoneNumber?.toString() || ""
                          );
                        }}
                      />
                    </button>
                    <span>→</span>
                    <span className="text-white/60">{t("vers")}</span>
                    <button
                      onClick={() =>
                        handlePhoneClick(
                          order.transactions[1].phoneNumber?.toString() || ""
                        )
                      }
                      className="hover:text-white/90 transition-colors flex items-center gap-1"
                    >
                      {order.transactions[1].phoneNumber}
                      <Copy
                        size={14}
                        className="opacity-50 hover:opacity-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopy(
                            order.transactions[1].phoneNumber?.toString() || ""
                          );
                        }}
                      />
                    </button>
                  </div>

                  <div>{renderQrButton(order)}</div>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center gap-1 mt-1">
                    <span className="text-sm text-white/70">ID: </span>
                    <span className="text-sm">{order.orderCode}</span>
                    <Copy
                      size={14}
                      className="opacity-50 hover:opacity-100"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCopy(order.orderCode?.toString() || "");
                      }}
                    />
                  </div>
                  <div className="text-xs text-white/60 mt-1">
                    {t("FraisFacture")}:{" "}
                    {order.transactions[0].debit === 1
                      ? t("destinataire")
                      : t("expéditeur")}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {showQr && qrCodeImg && (
          <ConfirmQrDialog
            showQRDialog={showQr}
            setShowQRDialog={setShowQr}
            lien={lien}
            codeQr={qrCodeImg}
            fromCurrency={fromCurrency}
          />
        )}
      </CollapsibleContent>
    </Collapsible>
  );
};

export default TransactionHistory;
