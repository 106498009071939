import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../components/ui/dialog";
import React, { useCallback, useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Claims } from "@/types/types";

const Transaction: React.FC = () => {
  const [transactions, setTransactions] = useState([
    { id: 1, client: 'John Doe', amount: 150, transactionId: 'TXN23456784d53', withdrawalNumber: '774540909', depositNumber: '774540909', good: 'OM -> Wave', status: 'En attente' },
    { id: 2, client: 'Jane Smith', amount: 200, transactionId: 'TXN23456784d54', withdrawalNumber: '774540910', depositNumber: '774540910', good: 'OM -> Wave', status: 'Résolue' },
    { id: 3, client: 'Alice Johnson', amount: 50, transactionId: 'TXN23456784d55', withdrawalNumber: '774540911', depositNumber: '774540911', good: 'Mobile -> MTN', status: 'En cours' },
    { id: 4, client: 'Robert Brown', amount: 100, transactionId: 'TXN23456784d56', withdrawalNumber: '774540912', depositNumber: '774540912', good: 'Wave -> OM', status: 'En attente' },
    { id: 5, client: 'Emily Clark', amount: 300, transactionId: 'TXN23456784d57', withdrawalNumber: '774540913', depositNumber: '774540913', good: 'Free -> Orange', status: 'Résolue' },
    { id: 6, client: 'Michael Lee', amount: 250, transactionId: 'TXN23456784d58', withdrawalNumber: '774540914', depositNumber: '774540914', good: 'Mobile -> Wave', status: 'En cours' },
    { id: 7, client: 'Sarah King', amount: 120, transactionId: 'TXN23456784d59', withdrawalNumber: '774540915', depositNumber: '774540915', good: 'Wave -> OM', status: 'En attente' },
    { id: 8, client: 'David White', amount: 180, transactionId: 'TXN23456784d60', withdrawalNumber: '774540916', depositNumber: '774540916', good: 'Free -> Orange', status: 'Résolue' },
    { id: 9, client: 'Sophia Adams', amount: 70, transactionId: 'TXN23456784d61', withdrawalNumber: '774540917', depositNumber: '774540917', good: 'Mobile -> Free', status: 'En cours' },
    { id: 10, client: 'James Martin', amount: 160, transactionId: 'TXN23456784d62', withdrawalNumber: '774540918', depositNumber: '774540918', good: 'OM -> Wave', status: 'En attente' },
  ]);
  

  // Gestion de la page et de l'état du popup
  const [currentPage, setCurrentPage] = useState(1);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<any>();
  const [claims, setClaims] = useState<Claims[]>([]);
  const backendUrlNet = 'http://localhost:3333'

  const itemsPerPage = 7; 
  const totalPages = Math.ceil(transactions.length / itemsPerPage); 
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentTransactions = transactions.slice(startIndex, startIndex + itemsPerPage);

  // Fonction pour changer de page
  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Fonction pour ouvrir le popup avec les détails
  const handleShowDetails = (transaction: any) => {
    setSelectedTransaction(transaction);
    setShowDetails(true);
  };
   useEffect(() => {
      fetchClaim();
    }, []); 
  const fetchClaim = useCallback(async () => {
   
    try {
      const response = await axios.get(`${backendUrlNet}/api/swaps/listClaim`);
      if (response.status === 200) {
        setClaims(response.data);
        console.log(response);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // setLoading(false);
    }
  }, []);
  // Fonction pour fermer le popup
  const handleClosePopup = () => {
    setShowDetails(false);
    setSelectedTransaction(null);
  };
  const { t } = useTranslation();

  return (
    <div className=" bg-white/5 p-4 rounded-lg shadow-md text-white">
      <h2 className="text-xl font-bold mb-4">Liste des Transactions</h2>
      <table className="min-w-full">
        <thead>
          <tr className="bg-gray-50">
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">ID</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Client</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Montant</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Numéro de Dépôt</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Numéro de Retrait</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">ID Transaction</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Swap</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Statut</th>
            {/* <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {currentTransactions.map((transaction) => (
            <tr key={transaction.id} className="border-b cursor-pointer" onClick={() => handleShowDetails(transaction)}>
              <td className="px-6 py-4 text-sm text-gray-100">{transaction.id}</td>
              <td className="px-6 py-4 text-sm text-gray-100">{transaction.client}</td>
              <td className="px-6 py-4 text-sm text-gray-100">{transaction.amount}</td>
              <td className="px-6 py-4 text-sm text-gray-100">{transaction.depositNumber}</td>
              <td className="px-6 py-4 text-sm text-gray-100">{transaction.withdrawalNumber}</td>
              <td className="px-6 py-4 text-sm text-gray-100">{transaction.transactionId}</td>
              <td className="px-6 py-4 text-sm text-gray-100">{transaction.good}</td>
              <td className=" px-3 py-2 text-sm text-gray-100">
                <span
                  className={`px-1 py-1 rounded-full text-xs ${
                    transaction.status === 'En attente'
                      ? 'bg-yellow-100 text-yellow-800'
                      : transaction.status === 'Complété'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  }`}
                >
                  {transaction.status}
                </span>
              </td>
              {/* <td className=" text-sm text-gray-100">
                <button className="px-4 py-2 bg-purple-800 text-white rounded-lg disabled:bg-purple-700"
                >Details</button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className='flex justify-center'>
        <div className="mt-4 flex justify-between items-center">
          <button
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-purple-800 text-white rounded-lg disabled:bg-purple-700"
          >
            <FaChevronLeft />
          </button>

          <div className="text-sm mx-1">
            Page {currentPage} / {totalPages}
          </div>

          <button
            onClick={() => goToPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-purple-800 text-white rounded-lg disabled:bg-purple-700"
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
      {/* Popup Details */}
      {showDetails && (
       <Dialog open={showDetails} onOpenChange={setShowDetails}>
       <DialogContent 
         className="
           bg-gradient-to-br from-purple-700 to-pink-600 
           border-none 
           text-white 
           w-[90%] 
           max-w-md 
           rounded-xl 
           mx-auto 
           my-auto
         "
       >
         <DialogHeader>
           <DialogTitle 
             className="
               text-xl 
               font-bold 
               text-white 
               mb-4 
               text-center 
             "
           >
             {t("titreFacture")}
           </DialogTitle>
         </DialogHeader>
 
         <div className="space-y-4 sm:space-y-6">
           {/* Retrait Section */}
           <div 
             className="
               bg-white/10 
               rounded-lg 
               p-3 
               sm:p-4 
               transition-all 
               duration-300 
               hover:bg-white/15
             "
           >
             <h3 className="text-sm font-medium mb-2">{t("titreRetait")}</h3>
             <div className="space-y-1 sm:space-y-2">
               <div className="flex justify-between text-xs sm:text-sm">
                 <span>{t("ServiceFacture")}</span>
                 <span className="font-medium">Om</span>
               </div>
               <div className="flex justify-between text-xs sm:text-sm">
                 <span>{t("NumeroFacture")}</span>
                 <span className="font-medium">{775457878}</span>
               </div>
               <div className="flex justify-between text-xs sm:text-sm">
                 <span>{t("MontantFacture")}</span>
                 <span className="font-medium">{100} FCFA</span>
               </div>
               <div className="flex justify-between text-xs sm:text-sm">
                 <span>{t("Etat")}</span>
                 <span className="font-medium">Success</span>
               </div>
             </div>
           </div>
 
           {/* Depot Section */}
           <div 
             className="
               bg-white/10 
               rounded-lg 
               p-3 
               sm:p-4 
               transition-all 
               duration-300 
               hover:bg-white/15
             "
           >
             <h3 className="text-sm font-medium mb-2">{t("titreDepot")}</h3>
             <div className="space-y-1 sm:space-y-2">
               <div className="flex justify-between text-xs sm:text-sm">
                 <span>{t("ServiceFacture")}</span>
                 <span className="font-medium">Wave</span>
               </div>
               <div className="flex justify-between text-xs sm:text-sm">
                 <span>{t("NumeroFacture")}</span>
                 <span className="font-medium">{774540909}</span>
               </div>
               <div className="flex justify-between text-xs sm:text-sm">
                 <span>{t("MontantFacture")}</span>
                 <span className="font-medium">{100} FCFA</span>
               </div>
               <div className="flex justify-between text-xs sm:text-sm">
                 <span>Etat</span>
                 <span className="font-medium">Success</span>
               </div>
             </div>
           </div>
 
           {/* Frais Section */}
           {/* <div 
             className="
               bg-white/10 
               rounded-lg 
               p-3 
               sm:p-4 
               transition-all 
               duration-300 
               hover:bg-white/15
             "
           >
             <h3 className="text-sm font-medium mb-2">{t("titreFrais")}</h3>
             <div className="space-y-1 sm:space-y-2">
               <div className="flex justify-between text-xs sm:text-sm">
                 <span>{t("FraisFacture")}</span>
                 <span className="font-medium">{transactionFee} FCFA</span>
               </div>
               <div className="flex justify-between text-xs sm:text-sm">
                 <span>{t("SupporteParFacture")}</span>
                 <span className="font-medium">
                   {isOnRetrait ? `${t("expéditeur")}` : `${t("destinataire")}`}
                 </span>
               </div>
               <div 
                 className="
                   flex 
                   justify-between 
                   font-medium 
                   pt-2 
                   border-t 
                   border-white/10 
                   text-sm 
                   sm:text-base
                 "
               >
                 <span>{t("TotalFacture")}</span>
                 <span>100 FCFA</span>
               </div>
             </div>
           </div> */}
         </div>
 
         <DialogFooter 
           className="
             sm:mt- 
             flex 
             flex-col 
             sm:flex-col
             space-y-2 
             sm:space-y-2 
             sm:space-x-1
           "
         >
          
          
           {/* <Button
            //  onClick={() => setShowSummary(false)}
             className="w-full py-2 bg-gradient-to-r from-pink-500 to-purple-600 hover:from-purple-600 hover:to-pink-500 text-white font-semibold text-base rounded-xl shadow-lg transform hover:scale-[1.02] transition-all duration-200 hover:shadow-pink-500/25 relative overflow-hidden group"
 
           >
             {t("buttonAnnulerTrans")}
           </Button> */}
         </DialogFooter>
       </DialogContent>
     </Dialog>
      )}
    </div>
  );
};

export default Transaction;
