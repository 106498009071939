import React from 'react';
import { Calculator, Info } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from './ui/tooltip';

interface RateCalculatorProps {
  fromCurrency: string;
  toCurrency: string;
  fromAmount: string;
  exchangeRate: number;
  fees: number;
  isRetraitBearingFees: boolean;
}

const RateCalculator: React.FC<RateCalculatorProps> = ({
  fromCurrency,
  toCurrency,
  fromAmount,
  exchangeRate,
  fees,
  isRetraitBearingFees,
}) => {
  const amount = parseFloat(fromAmount) || 0;
  const withoutFees = amount;
  const withFees = isRetraitBearingFees ? amount + fees : amount;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div className="flex items-center gap-1 cursor-help text-white/70 hover:text-white">
          <Calculator size={16} />
          <span className="text-sm">Voir le calcul</span>
        </div>
      </TooltipTrigger>
      <TooltipContent className="w-72 p-3 space-y-2">
        <div className="space-y-1">
          <div className="flex justify-between text-sm">
            <span>Montant de base:</span>
            <span>{amount.toFixed(2)} FCFA</span>
          </div>
          <div className="flex justify-between text-sm">
            <span>Frais ({isRetraitBearingFees ? 'expéditeur' : 'destinataire'}):</span>
            <span>{fees} FCFA</span>
          </div>
          <div className="flex justify-between text-sm font-medium border-t border-white/10 pt-1">
            <span>Total:</span>
            <span>{withFees.toFixed(2)} FCFA</span>
          </div>
        </div>
        
        <div className="border-t border-white/10 pt-2 space-y-1">
          <div className="text-xs text-white/70">Taux de change</div>
          <div className="flex justify-between text-sm">
            <span>1 {fromCurrency} =</span>
            <span>{exchangeRate.toFixed(4)} {toCurrency}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span>{amount} {fromCurrency} =</span>
            <span>{(amount * exchangeRate).toFixed(2)} {toCurrency}</span>
          </div>
        </div>

        <div className="text-xs text-white/50 mt-2">
          <Info size={12} className="inline mr-1" />
          Les taux sont mis à jour en temps réel
        </div>
      </TooltipContent>
    </Tooltip>
  );
};

export default RateCalculator;