import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/globals.css";
import App from "./App";
import { SwapProvider } from "./components/ui/Context/SwapContext";
import { TelegramWebApp } from "react-telegram-webapp";
async function validateHash() {
  console.log("validateHash function called");
  return true;
}
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <TelegramWebApp validateHash={validateHash}>

    <SwapProvider>
      <App />
    </SwapProvider>
    </TelegramWebApp>

  </React.StrictMode>
);
