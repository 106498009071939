import React from "react";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogTrigger,
    DialogDescription,
  } from "./ui/dialog";
  import { Button } from "./ui/button";

interface TransactionSummaryDialogProps {
  showSummary: boolean;
  setShowSummary: (open: boolean) => void;
  fromCurrency: string;
  fromPhone: string;
  fromAmount: string;
  toCurrency: string;
  toPhone: string;
  toAmount: string;
  transactionFee: number;
  isOnRetrait: boolean;
  totalWithFees: number;
  handleConfirmSwap: () => void;
}

const TransactionSummaryDialog: React.FC<TransactionSummaryDialogProps> = ({
  showSummary,
  setShowSummary,
  fromCurrency,
  fromPhone,
  fromAmount,
  toCurrency,
  toPhone,
  toAmount,
  transactionFee,
  isOnRetrait,
  totalWithFees,
  handleConfirmSwap,
}) => (
  <Dialog open={showSummary} onOpenChange={setShowSummary}>
    <DialogContent className="bg-gradient-to-br from-purple-700/95 to-pink-600/95 border-none text-white">
      <DialogHeader>
        <DialogTitle className="text-xl font-bold text-white mb-4">
          Résumé de la transaction
        </DialogTitle>
      </DialogHeader>

      <div className="space-y-6 ">
        <div className="bg-white/10 rounded-lg p-4">
          <h3 className="text-sm font-medium mb-2">Détails du retrait</h3>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span>Service</span>
              <span className="font-medium">{fromCurrency}</span>
            </div>
            <div className="flex justify-between">
              <span>Numéro</span>
              <span className="font-medium">{fromPhone}</span>
            </div>
            <div className="flex justify-between">
              <span>Montant</span>
              <span className="font-medium">{fromAmount} FCFA</span>
            </div>
          </div>
        </div>

        <div className="bg-white/10 rounded-lg p-4">
          <h3 className="text-sm font-medium mb-2">Détails du dépôt</h3>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span>Service</span>
              <span className="font-medium">{toCurrency}</span>
            </div>
            <div className="flex justify-between">
              <span>Numéro</span>
              <span className="font-medium">{toPhone}</span>
            </div>
            <div className="flex justify-between">
              <span>Montant</span>
              <span className="font-medium">{toAmount} FCFA</span>
            </div>
          </div>
        </div>

        <div className="bg-white/10 rounded-lg p-4">
          <h3 className="text-sm font-medium mb-2">Frais de transaction</h3>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span>Frais</span>
              <span className="font-medium">{transactionFee} FCFA</span>
            </div>
            <div className="flex justify-between">
              <span>Supporté par</span>
              <span className="font-medium">
                {isOnRetrait ? "L'expéditeur" : "Le destinataire"}
              </span>
            </div>
            <div className="flex justify-between font-medium pt-2 border-t border-white/10">
              <span>Total</span>
              <span>{totalWithFees} FCFA</span>
            </div>
          </div>
        </div>
      </div>

      <DialogFooter className="mt-2 ">
        <Button
          variant="outline"
          onClick={() => setShowSummary(false)}
          className="bg-white/10 hover:bg-white/20 text-white border-none"
        >
          Annuler
        </Button>
        <Button
          onClick={handleConfirmSwap}
          className="bg-gradient-to-r  from-green-500 to-green-600 text-white hover:from-green-600 hover:to-green-700"
        >
          Confirmer la transaction
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
);

export default TransactionSummaryDialog;
