import React, { useState, useEffect } from "react";
import { ArrowUpDown, Loader2 } from "lucide-react";
import { Card, CardContent, CardHeader } from "./ui/card";
import { Button } from "./ui/button";
import logo from "../assets/money-swap-logo.png";
import TransactionHistory from "./TransactionHistory";
import { Currency, Transaction } from "../types/types";
import { TooltipProvider } from "./ui/tooltip";
import RateCalculator from "./RateCalculator";
import ConfirmationWave from "./Confirmation-wave";
import OrangeMoneyDialog from "./Confirmation-om";
import TransactionSummaryDialog from "./Transaction";
import RetraitForm from "./forms";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import SwapForm from "./forms";
const SwapInterface: React.FC = () => {
  const backend = "https://api.telegram.creditemoi.com";
  const TRANSACTION_FEE = 20;
  const [error, setError] = useState("");
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [fromCurrency, setFromCurrency] = useState("");
  const [toCurrency, setToCurrency] = useState("");
  const [fromPhone, setFromPhone] = useState("");
  const [toPhone, setToPhone] = useState("");
  const [fromAmount, setFromAmount] = useState("200");
  const [toAmount, setToAmount] = useState("200");
  const [showOm, setShowOm] = useState(false);
  const [codeQr, setCodeQr] = useState("");
  const [lienWave, setLienwave] = useState<string>("");
  const [isOnRetrait, setIsOnRetrait] = useState(true);
  const [isOnDepot, setIsOnDepot] = useState(false);
  const [showQRDialog, setShowQRDialog] = useState(false);
  const [debit, setDebit] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [exchangeRate, setExchangeRate] = useState<number>(1.02);
  const [userId, setUserId] = useState<number | string>();
  const [userData, setUserData] = useState<UserData | null>(null);
  const MIN_AMOUNT = 100;
  const MAX_AMOUNT = 1000000;
  const [errors, setErrors] = useState({
    fromPhone: "",
    toPhone: "",
    amount: "",
  });
  // const TelegramWebApp = window.Telegram.WebApp;
  //const user = TelegramWebApp.initDataUnsafe;
  interface UserData {
    id: number;
    first_name: string;
    last_name: string;
  }
  // Telegram integration
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const TelegramWebApp = window.Telegram.WebApp;
      const user = TelegramWebApp.initDataUnsafe;
      if (user && user.user) {
        const newUserData = {
          id: user.user.id,
          first_name: user.user.first_name,
          last_name: user.user.last_name,
        };
        if (JSON.stringify(newUserData) !== JSON.stringify(userData)) {
          setUserData(newUserData);
        }
      }
    }
  }, [userData]);
  const fetchTransactions = async () => {
    if (!userData) {
      setIsLoadingTransactions(false);
      console.log("Waiting for user data...");
      return;
    }

    if (!userData.id) {
      setIsLoadingTransactions(false);
      console.error("User ID is missing");
      // setError("User ID is required");
      return;
    }

    setIsLoadingTransactions(true);

    try {
      const response = await fetch(
        `${backend}/api/transaction?userId=${userData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (!data?.transaction) {
        throw new Error("No transaction data received");
      }

      console.log(data.transaction);
      setTransactions(data.transaction);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
      // handle specific error types
      // if (error instanceof TypeError) {
      //   setError("Network error occurred");
      // } else {
      //   setError("Failed to fetch transactions");
      // }
    } finally {
      setIsLoadingTransactions(false);
    }
  };

  useEffect(() => {
    if (userData?.id) {
      fetchTransactions();
    }
  }, [userData]);

  const validateInputs = () => {
    const newErrors = {
      fromPhone: "",
      toPhone: "",
      amount: "",
    };

    // Phone number validation (9 digits for Senegal)
    if (!fromPhone) {
      newErrors.fromPhone = "Le numéro est requis";
    } else if (!/^(77|78|75|76|70)\d{7}$/.test(fromPhone)) {
      newErrors.fromPhone = "Numéro invalide (format: 7X XXX XX XX)";
    }

    if (!toPhone) {
      newErrors.toPhone = "Le numéro est requis";
    } else if (!/^(77|78|75|76|70)\d{7}$/.test(toPhone)) {
      newErrors.toPhone = "Numéro invalide (format: 7X XXX XX XX)";
    }

    // Amount validation
    if (!fromAmount) {
      newErrors.amount = "Le montant est requis";
    } else {
      const amount = parseFloat(fromAmount);
      if (isNaN(amount)) {
        newErrors.amount = "Montant invalide";
      } else if (amount < MIN_AMOUNT) {
        newErrors.amount = `Le montant minimum est de ${MIN_AMOUNT} FCFA`;
      } else if (amount > MAX_AMOUNT) {
        newErrors.amount = `Le montant maximum est de ${MAX_AMOUNT} FCFA`;
      }
    }

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error !== "");
  };
  // Amount handler
  const handleAmountChange = (value: string) => {
    const numericValue = parseFloat(value) || 0;
    setFromAmount(value);

    // Clear amount error when user types
    if (errors.amount) {
      setErrors((prev) => ({ ...prev, amount: "" }));
    }

    // Real-time maximum amount validation
    if (numericValue > MAX_AMOUNT) {
      setErrors((prev) => ({
        ...prev,
        amount: `Le montant maximum est de ${MAX_AMOUNT} FCFA`,
      }));
    }

    // Calculate receiving amount based on fee bearer
    if (isOnRetrait) {
      setToAmount(value);
    } else if (isOnDepot) {
      const receiverAmount = Math.max(0, numericValue - TRANSACTION_FEE);
      setToAmount(receiverAmount.toString());
    }
  };
  const handlePhoneChange = (value: string, type: "from" | "to") => {
    // Only allow digits
    const cleanedValue = value.replace(/\D/g, "");

    if (type === "from") {
      setFromPhone(cleanedValue);
      // Clear error when user starts typing
      if (errors.fromPhone) {
        setErrors((prev) => ({ ...prev, fromPhone: "" }));
      }
    } else {
      setToPhone(cleanedValue);
      if (errors.toPhone) {
        setErrors((prev) => ({ ...prev, toPhone: "" }));
      }
    }
  };

  // Fee toggle handlers
  const toggleSupportFraisRetrait = (checked: boolean) => {
    if (checked && !isOnRetrait) {
      // Switching to sender bearing fees
      setIsOnRetrait(true);
      setIsOnDepot(false);
      if (fromAmount) {
        // Receiver should get the full amount
        setToAmount(fromAmount);
      }
    } else if (!checked && isOnRetrait) {
      // Switching to receiver bearing fees
      setIsOnRetrait(false);
      setIsOnDepot(true);
      if (fromAmount) {
        // Receiver gets amount minus fees
        const numericAmount = parseFloat(fromAmount);
        const receiverAmount = Math.max(0, numericAmount - TRANSACTION_FEE);
        setToAmount(receiverAmount.toString());
      }
    }
  };

  const toggleSupportFraisDepot = (checked: boolean) => {
    if (checked && !isOnDepot) {
      // Switching to receiver bearing fees
      setIsOnDepot(true);
      setIsOnRetrait(false);
      setDebit(0);
      if (fromAmount) {
        // Receiver gets amount minus fees
        const numericAmount = parseFloat(fromAmount);
        const receiverAmount = Math.max(0, numericAmount - TRANSACTION_FEE);
        setToAmount(receiverAmount.toString());
      }
    } else if (!checked && isOnDepot) {
      // Switching to sender bearing fees
      setIsOnDepot(false);
      setIsOnRetrait(true);
      if (fromAmount) {
        setToAmount(fromAmount);
      }
    }
  };

  // Currency swap handler
  const handleCurrencySwap = () => {
    setFromCurrency(toCurrency);
    setToCurrency(fromCurrency);

    // Swap amounts
    const tempAmount = fromAmount;
    setFromAmount(toAmount);
    setToAmount(tempAmount);

    // Swap phones
    setFromPhone(toPhone);
    setToPhone(fromPhone);

    // Swap fee bearer status
    const tempRetrait = isOnRetrait;
    setIsOnRetrait(isOnDepot);
    setIsOnDepot(tempRetrait);
    setDebit(isOnDepot ? 0 : 1);
  };

  useEffect(() => {
    fetchCurrencies();

    const getExchangeRate = async () => {
      const mockRates: { [key: string]: number } = {
        Wave_OrangeMoney: 1.02,
        OrangeMoney_Wave: 0.98,
      };
      const pair = `${fromCurrency}_${toCurrency}`.replace(/\s/g, "");
      setExchangeRate(mockRates[pair] || 1);
    };

    getExchangeRate();
  }, [fromCurrency, toCurrency]);

  const fetchCurrencies = async () => {
    try {
      const response = await fetch(`${backend}/api/listswap`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "true",
        },
      });

      const result = await response.json();
      setCurrencies(Object.values(result));
      setFromCurrency(result.waveToOm.fromCurrency);
      setToCurrency(result.waveToOm.toCurrency);
      if (result.waveToOm.value) {
        setExchangeRate(result.waveToOm.value);
      }
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  const handleSwap = () => {
    if (!validateInputs()) {
      return;
    }
    setShowSummary(true);
  };
  const handleConfirmSwap = async () => {
    setErrors({
      fromPhone: "",
      toPhone: "",
      amount: "",
    });

    setShowSummary(false);
    setIsLoading(true);

    const swapData = {
      phoneNumberRetrait: fromPhone,
      amountRetrait: fromAmount,
      amountDepot: toAmount,
      phoneNumberDepot: toPhone,
      user: userData,
      debit: debit,
      compositionorder: [
        {
          goodId: currencies.find(
            (currency) => currency.fromCurrency === fromCurrency
          )?.id,
        },
      ],
      codeGood: currencies.find(
        (currency) => currency.fromCurrency === fromCurrency
      )?.codeGood,
    };

    try {
      const response = await fetch(`${backend}/api/swaps`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "true",
        },
        body: JSON.stringify(swapData),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(`Erreur lors du swap! status: ${response.status}`);
      }

      if (response.status === 200) {
        setLienwave(result.lienwave);
        setCodeQr(result.qrCodeImg);
      }

      if (fromCurrency === "wave") {
        setShowQRDialog(true);
      } else if (fromCurrency === "orange Money") {
        setShowOm(true);
      }
      setFromAmount("");
      setToAmount("");
      setFromPhone("");
      setToPhone("");
      await fetchTransactions();
    } catch (error) {
      console.error("Erreur lors du swap:", error);
      alert("Erreur lors du swap, veuillez réessayer.");
    } finally {
      setIsLoading(false);
    }
  };
  const totalWithFees = isOnRetrait
    ? parseFloat(fromAmount) + TRANSACTION_FEE
    : parseFloat(fromAmount);
  return (
    <TooltipProvider>
      <div className="min-h-screen bg-gradient-to-br from-purple-700 to-pink-600 flex items-center justify-center p-4">
        <div className="w-full max-w-md p-4 mb-16">
          {" "}
          <Card className="w-full max-w-md backdrop-blur-lg bg-white/30 border-none shadow-xl relative">
            <div className="absolute -top-10 left-1/2 transform -translate-x-1/2">
              <img
                src={logo}
                className="w-24 h-24 rounded-full shadow-lg"
                alt="Logo"
              />
            </div>

            <CardHeader className="pt-16">
              {" "}
              {/* Increased top padding */}
              <h2 className="text-2xl font-bold text-center text-white">
                Mobile Money Swap
              </h2>
              <div className="text-center text-xl text-red-800">{error}</div>
            </CardHeader>

            <CardContent className="space-y-6">
              <SwapForm
                currency={fromCurrency}
                amount={fromAmount}
                phone={fromPhone}
                currencies={currencies}
                TRANSACTION_FEE={TRANSACTION_FEE}
                isOnRetrait={isOnRetrait}
                errors={errors}
                setCurrency={setFromCurrency}
                handleAmountChange={handleAmountChange}
                setPhone={setFromPhone}
                toggleSupportFrais={toggleSupportFraisRetrait}
                backend={backend}
                label_zone="Retrait"
              />

              <div className="flex justify-center">
                <Button
                  onClick={handleCurrencySwap}
                  variant="ghost"
                  className="mt-5 hover:bg-white/10 rounded-full transition-colors"
                >
                  <ArrowUpDown className="text-white" />
                </Button>
              </div>

              <SwapForm
                currency={toCurrency}
                amount={toAmount}
                phone={toPhone}
                currencies={currencies}
                TRANSACTION_FEE={TRANSACTION_FEE}
                isOnRetrait={isOnDepot}
                errors={errors}
                setCurrency={setToCurrency}
                handleAmountChange={handleAmountChange}
                setPhone={setToPhone}
                toggleSupportFrais={toggleSupportFraisDepot}
                backend={backend}
                label_zone="Depot"
              />
              <Button
                onClick={handleSwap}
                className="w-full bg-gradient-to-br from-purple-800 to-pink-600 hover:bg-pink-500 text-white font-semibold"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex items-center gap-2">
                    <Loader2 className="h-4 w-4 animate-spin" />
                    Transaction en cours...
                  </div>
                ) : (
                  "Swap now"
                )}
              </Button>
              <div className="flex items-center justify-between text-sm text-white/70">
                <RateCalculator
                  fromCurrency={fromCurrency}
                  toCurrency={toCurrency}
                  fromAmount={fromAmount}
                  exchangeRate={exchangeRate}
                  fees={TRANSACTION_FEE}
                  isRetraitBearingFees={isOnRetrait}
                />
                <span>
                  1 {fromCurrency} = {exchangeRate.toFixed(4)} {toCurrency}
                </span>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="fixed bottom-0 left-0 right-0 bg-purple-800/95 backdrop-blur-sm rounded-t-2xl shadow-lg transition-transform duration-300 max-h-[60vh]">
          <div className="w-full max-w-md mx-auto">
            <div className="flex justify-center py-2">
              <div className="w-12 h-1 bg-white/20 rounded-full" />{" "}
            </div>
            <TransactionHistory
              transactions={transactions}
              isLoading={isLoadingTransactions}
            />
          </div>
        </div>

        <ConfirmationWave
          showQRDialog={showQRDialog}
          setShowQRDialog={setShowQRDialog}
          lienWave={lienWave}
          codeQr={codeQr}
        />
        <OrangeMoneyDialog showOm={showOm} setShowOm={setShowOm} />

        <TransactionSummaryDialog
          showSummary={showSummary}
          setShowSummary={setShowSummary}
          fromCurrency={fromCurrency}
          fromPhone={fromPhone}
          fromAmount={fromAmount}
          toCurrency={toCurrency}
          toPhone={toPhone}
          toAmount={toAmount}
          transactionFee={TRANSACTION_FEE}
          isOnRetrait={true}
          totalWithFees={totalWithFees}
          handleConfirmSwap={handleConfirmSwap}
        />
      </div>
    </TooltipProvider>
  );
};

export default SwapInterface;
