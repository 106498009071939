import React, { useCallback, useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaEllipsisV } from 'react-icons/fa';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Claims, Orders, Transactions } from '../../types/types';
import { Button } from '../../components/ui/button';

const Claim: React.FC = () => {
  const backendUrlNet = process.env.REACT_APP_TEST === "1"
    ? process.env.REACT_APP_BACKEND_URL_NGROK
    : process.env.REACT_APP_BACKEND_URL_NET;

  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownStates, setDropdownStates] = useState<{ [key: number]: boolean }>({});
  const [showDetails, setShowDetails] = useState(false);
  const [claims, setClaims] = useState<Claims[]>([]);
  const totalPages = Math.ceil(claims.length / itemsPerPage);
  const [transactions, setTransactions] = useState<Transactions>();
  const [message, setMessage] = useState();

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentClaims = claims.slice(startIndex, startIndex + itemsPerPage);

  // Fonction pour changer de page
  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleActionChange = (claimId: number, action: string) => {
    setClaims((prevClaims) =>
      prevClaims.map((claim) =>
        claim.id === claimId ? { ...claim, status: action } : claim
      )
    );

    // Fermer le menu déroulant après l'action
    setDropdownStates((prevStates) => ({ ...prevStates, [claimId]: false }));
  };

  const handleShowDetails = async (orderCode: string) => {
    setShowDetails(true);
    try {
      const response = await axios.get(
        `${backendUrlNet}/api/swaps/verifyClaim?orderCode=${orderCode}`
      );
      if (response.status === 200 && response.data?.dataOrders) {
        setTransactions(response.data.dataOrders);
        setMessage(response.data.verificationDepot)
        console.log(response.data)
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      // setTransactions();
    }
  };

  const toggleDropdown = (claimId: number) => {
    setDropdownStates((prevStates) => ({
      ...prevStates,
      [claimId]: !prevStates[claimId], // Inverse l'état du menu déroulant
    }));
  };

  const { t } = useTranslation();

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const fetchCurrencies = useCallback(async () => {
    try {
      const response = await axios.get(`${backendUrlNet}/api/swaps/listClaim`);
      if (response.status === 200) {
        setClaims(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [backendUrlNet]);

  return (
    <div className="bg-white/5 p-4 rounded-lg shadow-md text-white">
      <h2 className="text-xl font-bold mb-4">Réclamations Récentes</h2>
      <table className="min-w-full">
        <thead>
          <tr className="bg-gray-50">
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">ID</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Client</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Montant</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Numéro de Dépôt</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Numéro de Retrait</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">ID Transaction</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Description</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Statut</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentClaims.map((claim) => (
            <tr key={claim.id} className="border-b">
              <td className="px-6 py-4 text-sm text-gray-100">{claim.id}</td>
              <td className="px-6 py-4 text-sm text-gray-100">{claim.clientId}</td>
              <td className="px-6 py-4 text-sm text-gray-100">{claim.amount}</td>
              <td className="px-6 py-4 text-sm text-gray-100">{claim.numberDebit}</td>
              <td className="px-6 py-4 text-sm text-gray-100">{claim.numberRetrait}</td>
              <td className="px-6 py-4 text-sm text-gray-100">{claim.orderCode}</td>
              <td className="px-6 py-4 text-sm text-gray-100">{claim.description}</td>
              <td className="px-3 py-2 text-sm text-gray-100">
                <span
                  className={`px-1 py-1 rounded-full text-xs ${claim.status === 'new'
                    ? 'bg-yellow-100 text-yellow-800'
                    : claim.status === 'gerer'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                    }`}
                >
                  {claim.status}
                </span>
              </td>
              <td className="px-6 py-2 text-sm text-gray-100 relative">
                <button
                  className="mx-4 bg-gray-200 text-gray-800 p-2 rounded"
                  onClick={() => toggleDropdown(claim.id)}
                >
                  <FaEllipsisV />
                </button>
                {dropdownStates[claim.id] && (
                  <div className="absolute mt-1 bg-gradient-to-br from-purple-700 to-pink-600 border rounded shadow-lg z-50 w-full">
                    <div className="pl-2 pr-2 py-2">
                      <button
                        className="bg-white/5 py-2 text-white border rounded shadow-lg hover:bg-purple-700 w-full"
                        onClick={() => handleActionChange(claim.id, "Gérer")}
                      >
                        Gérer
                      </button>
                      <button
                        className="bg-white/5 py-2 mt-1 text-white border rounded shadow-lg hover:bg-purple-700 w-full"
                        onClick={() => handleActionChange(claim.id, "Clôturée")}
                      >
                        Clore
                      </button>
                      <button
                        className="bg-white/5 mt-1 text-white border rounded shadow-lg hover:bg-purple-700 w-full"
                        onClick={() => handleActionChange(claim.id, "En cours")}
                      >
                        Mettre en cours
                      </button>
                      <button
                        className="bg-white/5 mt-1 text-white border rounded shadow-lg hover:bg-purple-700 w-full"
                        onClick={() => handleShowDetails(claim.orderCode)}
                      >
                        Voir la transaction
                      </button>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-center">
        <div className="mt-4 flex justify-between items-center">
          <button
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-purple-800 text-white rounded-lg disabled:bg-purple-700"
          >
            <FaChevronLeft />
          </button>
          <div className="text-sm mx-1">
            Page {currentPage} / {totalPages}
          </div>
          <button
            onClick={() => goToPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-purple-800 text-white rounded-lg disabled:bg-purple-700"
          >
            <FaChevronRight />
          </button>
        </div>
      </div>

      {showDetails && (
        <Dialog open={showDetails} onOpenChange={setShowDetails}>
          <DialogContent className="bg-gradient-to-br from-purple-700 to-pink-600 border-none text-white w-[90%] max-w-md rounded-xl mx-auto my-auto">
            <DialogHeader>
              <DialogTitle className="text-xl font-bold text-white mb-4 text-center">
                {t("titreFacture")}
              </DialogTitle>
            </DialogHeader>
            <div className="space-y-4 sm:space-y-6">
          {/* Retrait Section */}
          <div 
            className="
              bg-white/10 
              rounded-lg 
              p-3 
              sm:p-4 
              transition-all 
              duration-300 
              hover:bg-white/15
            "
          >
            <div className="flex justify-center ">
                <span className='text-xs sm:text-lg'>Message: {message} </span>
                {/* <span  className='text-xs sm:text-sm' >{message}</span> */}
              </div>
           
          </div>
          <div 
            className="
              bg-white/10 
              rounded-lg 
              p-3 
              sm:p-4 
              transition-all 
              duration-300 
              hover:bg-white/15
            "
          >

            <h3 className="text-sm font-medium mb-2">{t("titreRetait")}</h3>
            <div className="space-y-1 sm:space-y-2">
              <div className="flex justify-between text-xs sm:text-sm">
                <span>{t("ServiceFacture")}</span>
                <span className="font-medium">{transactions?.transactions[0].currency}</span>
              </div>
              <div className="flex justify-between text-xs sm:text-sm">
                <span>{t("NumeroFacture")}</span>
                <span className="font-medium">{transactions?.transactions[0].phoneNumber}</span>
              </div>
              <div className="flex justify-between text-xs sm:text-sm">
                <span>{t("MontantFacture")}</span>
                <span className="font-medium">{transactions?.transactions[0].amount} FCFA</span>
              </div>
              <div className="flex justify-between text-xs sm:text-sm">
                <span>{t("Etat")}</span>
                <span className="font-medium">{transactions?.transactions[0].status} </span>
              </div>
            </div>
          </div>

          {/* Depot Section */}
          <div 
            className="
              bg-white/10 
              rounded-lg 
              p-3 
              sm:p-4 
              transition-all 
              duration-300 
              hover:bg-white/15
            "
          >
            <h3 className="text-sm font-medium mb-2">{t("titreDepot")}</h3>
            <div className="space-y-1 sm:space-y-2">
              <div className="flex justify-between text-xs sm:text-sm">
                <span>{t("ServiceFacture")}</span>
                <span className="font-medium">{transactions?.transactions[1].currency}</span>
              </div>
              <div className="flex justify-between text-xs sm:text-sm">
                <span>{t("NumeroFacture")}</span>
                <span className="font-medium">{transactions?.transactions[1].phoneNumber}</span>
              </div>
              <div className="flex justify-between text-xs sm:text-sm">
                <span>{t("MontantFacture")}</span>
                <span className="font-medium">{transactions?.transactions[1].amount} FCFA</span>
              </div>
              <div className="flex justify-between text-xs sm:text-sm">
                <span>{t("Etat")}</span>
                <span className="font-medium">{transactions?.transactions[1].status} </span>
              </div>
            </div>
          </div>

          {/* Frais Section */}
          
        </div>
        <DialogFooter 
          className="
            sm:mt- 
            flex 
            flex-col 
            sm:flex-col
            space-y-2 
            sm:space-y-2 
            sm:space-x-1
          "
        >
         {transactions?.transactions[0].status === "success" && transactions?.transactions[1].status === "failed" ? 
         <Button
         // onClick={handleConfirmSwap}
         className="w-full py-2 bg-gradient-to-r from-pink-500 to-purple-600 hover:from-purple-600 hover:to-pink-500 text-white font-semibold text-base rounded-xl shadow-lg transform hover:scale-[1.02] transition-all duration-200 hover:shadow-pink-500/25 relative overflow-hidden group"

        
       >
         {t("Renvoyer Depot")}
       </Button>
         :""}
          
        </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Claim;
