import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogTrigger,
  DialogDescription,
} from "./ui/dialog";
import { Button } from "./ui/button";
import { Phone } from "lucide-react";

interface OrangeMoneyDialogProps {
  showOm: boolean;
  setShowOm: (open: boolean) => void;
  title?: string;
  message?: string;
  closeButtonText?: string;
}

const OrangeMoneyDialog: React.FC<OrangeMoneyDialogProps> = ({
  showOm,
  setShowOm,
  title = "Instructions Orange Money",
  message = "Veuillez composer le #144# puis valider votre retrait Orange Money",
  closeButtonText = "J'ai compris",
}) => (
  <Dialog open={showOm} onOpenChange={setShowOm}>
    <DialogContent className="bg-gradient-to-br from-purple-700/95 to-pink-600/95 border-none text-white">
      <DialogHeader>
        <DialogTitle className="text-xl font-bold">
          {title}
        </DialogTitle>
      </DialogHeader>

      <div className="p-6 bg-white/10 rounded-lg">
        <div className="flex items-center justify-center gap-4 mb-4">
          <Phone className="w-8 h-8 text-orange-500" />
          <span className="text-2xl font-bold">#144#</span>
        </div>
        <p className="text-center text-white/70">
          {message}
        </p>
      </div>

      <Button
        onClick={() => setShowOm(false)}
        className="w-full mt-4 bg-white/20 hover:bg-white/30"
      >
        {closeButtonText}
      </Button>
    </DialogContent>
  </Dialog>
);

export default OrangeMoneyDialog;

  //   <Dialog open={showOm} onOpenChange={setShowOm}>
  //     <DialogContent className="bg-gradient-to-br from-purple-700/95 to-pink-600/95 border-none text-white">
  //       <DialogHeader>
  //         <DialogTitle className="text-xl font-bold">
  //           Instructions Orange Money
  //         </DialogTitle>
  //       </DialogHeader>

  //       <div className="p-6 bg-white/10 rounded-lg">
  //         <div className="flex items-center justify-center gap-4 mb-4">
  //           <Phone className="w-8 h-8 text-orange-500" />
  //           <span className="text-2xl font-bold">#144#</span>
  //         </div>
  //         <p className="text-center text-white/70">
  //           Veuillez composer le #144# puis valider votre retrait Orange Money
  //         </p>
  //       </div>

  //       <Button
  //         onClick={() => setShowOm(false)}
  //         className="w-full mt-4 bg-white/20 hover:bg-white/30"
  //       >
  //         J'ai compris
  //       </Button>
  //     </DialogContent>
  //   </Dialog>
  // );