import RecentTickets from '../../components/support/RecentTickets'
import StatsCard from '../../components/support/StatsCard'


function Home() {
  return (
    <div className="flex min-h-screen bg-gray-100">
      <main className="flex-1 p-6 bg-gradient-to-br from-purple-700 to-pink-600">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
            <StatsCard title="Tickets Ouverts" value={45} />
            <StatsCard title="Tickets Résolus" value={120} />
            <StatsCard title="Satisfaction Clients" value="95%" />
          </div>
          <RecentTickets />

          </main>
    </div>
  )
}

export default Home