import { CardContent, Card, CardHeader } from "../components/ui/card";
import { TooltipProvider } from "../components/ui/tooltip";
import { Currency, Orders, UserData } from "../types/types";
import React, { useState, useEffect, useCallback } from "react";
import logo from "../assets/Final Logo/Logo File/Favicon-Icon.png";
import TransactionHistory from "../components/props/TransactionHistory";
import { Button } from "../components/ui/button";
import { ArrowUpDown, Loader2 } from "lucide-react";
import FormProps from "../components/props/formProps";
import ConfirmQrDialog from "../components/Dialogs/ConfirmaQrDialog";
import ConfirmDialog from "../components/Dialogs/ConfirmDialog";
import TransactionSummaryDialog from "../components/props/TransactionSummary";
import AlertError from "../components/Dialogs/AlertError";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import TransactionStatus from "../components/Dialogs/TransactionStatus";
import HomeSticker from "../components/ui/StickerHome";

export const getUniquePhoneNumbers = (orders: Orders[]): string[] => {
  const uniqueNumbers = new Set<string>();
  orders.forEach((order) => {
    order.transactions.forEach((transaction) => {
      if (transaction.phoneNumber) {
        uniqueNumbers.add(transaction.phoneNumber.toString());
      }
    });
  });
  return Array.from(uniqueNumbers).slice(0, 5); // Get last 5 unique numbers
};
const SwapInterface: React.FC = () => {
  const [transactions, setTransactions] = useState<Orders[]>([]);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [selectedCaption1, setSelectedCaption1] = useState("");
  const [selectedCaption11, setSelectedCaption11] = useState("");
  const [selectedCaption12, setSelectedCaption12] = useState("");
  const [isOpposite, setIsOpposite] = useState(false);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [fromCurrency, setFromCurrency] = useState("");
  const [toCurrency, setToCurrency] = useState("");
  const [currencySelected, setCurrencySelected] = useState<Currency[]>([]);
  const [fromPhone, setFromPhone] = useState("");
  const [toPhone, setToPhone] = useState("");
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [codeQr, setCodeQr] = useState("");
  const [lienRetrait, setLienRetrait] = useState<string>("");
  const [isOnRetrait, setIsOnRetrait] = useState(true);
  const [isOnDepot, setIsOnDepot] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showQRDialog, setShowQRDialog] = useState(false);
  const [debit, setDebit] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [exchangeRate, setExchangeRate] = useState<number>(1.02);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [disabledInputAmount, setDisabledInputAmount] = useState(false);
  const [disabledSelect, setDisabledSelect] = useState(true);
  const [transactionFee, setTransactionFee] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [orderCode, setOrderCode] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { user, isLoading: isAuthLoading, checkAuth } = useAuth();

  const [showPolling, setShowPolling] = useState(false);
  const backendUrlNet =
    process.env.REACT_APP_TEST === "1"
      ? process.env.REACT_APP_BACKEND_URL_NGROK
      : process.env.REACT_APP_BACKEND_URL_NET;
  const MIN_AMOUNT = 100;
  const MAX_AMOUNT = 1000000;
  const [error, setError] = useState<string | null>(null);
  const [statusTransaction, setStatusTransaction] = useState<
    "success" | "pending" | "failed"
  >("pending");
  const [errors, setErrors] = useState({
    fromPhone: "",
    toPhone: "",
    amount: "",
    selectedCaption1: "",
  });
  const [recentNumbers, setRecentNumbers] = useState<string[]>([]);

  const { t } = useTranslation();

  const fetchTransactions = useCallback(async () => {
    if (!userData?.id) {
      return;
    }

    setIsLoadingTransactions(true);
    try {
      const response = await axios.get(
        `${backendUrlNet}/api/swaps/transaction?userId=${userData.id}`
      );
      if (response.status === 200 && response.data?.orders) {
        setTransactions(response.data.orders);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
    } finally {
      setIsLoadingTransactions(false);
    }
  }, [userData, backendUrlNet]);

  const fetchCurrencies = useCallback(async () => {
    try {
      const response = await axios.get(`${backendUrlNet}/api/swaps/list`);
      if (response.status === 200) {
        setCurrencies(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [backendUrlNet]);
  const handleSelect = (value: string) => {
    if (errors.selectedCaption1) {
      setErrors((prev) => ({ ...prev, selectedCaption1: "" }));
    }
    const selectedItem = currencies.find((item) => item.caption === value);
    if (selectedItem) {
      const metaData =
        typeof selectedItem.meta === "string"
          ? JSON.parse(selectedItem.meta)
          : selectedItem.meta;

      if (metaData) {
        setSelectedCaption1(value);
        setSelectedCaption11(metaData.from_service_name);
        setSelectedCaption12(metaData.to_service_name);
        setCurrencySelected([selectedItem]);
        setFromCurrency(metaData.from_service_name);
        setToCurrency(metaData.to_service_name);
        setIsOpposite(false);
        setDisabledInputAmount(true);
      }
    }
  };
  const switchCurrency = () => {
    if (currencySelected.length > 0) {
      const currentCurrency = currencySelected[0];

      if (isOpposite && currentCurrency.oppositeGood) {
        setSelectedCaption1(currentCurrency.caption);
        setSelectedCaption11(currentCurrency.meta.to_service_name);
        setSelectedCaption12(currentCurrency.meta.from_service_name);
        setCurrencySelected([currentCurrency]);
      } else if (currentCurrency.oppositeGood) {
        setSelectedCaption1(currentCurrency.oppositeGood.caption);
        setSelectedCaption11(currentCurrency.oppositeGood.meta.to_service_name);
        setSelectedCaption12(
          currentCurrency.oppositeGood.meta.from_service_name
        );
        setCurrencySelected([currentCurrency]);
      }
      setIsOpposite(!isOpposite);
    }
  };

  const validateInputs = () => {
    const newErrors = {
      fromPhone: "",
      toPhone: "",
      amount: "",
      selectedCaption1: "",
    };
    if (!selectedCaption1) {
      newErrors.selectedCaption1 = t("selectEmpty");
      setErrors(newErrors);
      return false;
    }
    if (!fromPhone) {
      newErrors.fromPhone = t("phoneEmpty");
    } else if (!/^(77|78|75|76|70)\d{7}$/.test(fromPhone)) {
      newErrors.fromPhone = t("errorNumerInvalid");
    }

    if (!toPhone) {
      newErrors.toPhone = t("phoneEmpty");
    } else if (!/^(77|78|75|76|70)\d{7}$/.test(toPhone)) {
      newErrors.toPhone = t("errorNumerInvalid");
    }

    if (!fromAmount) {
      newErrors.amount = t("amountEmpty");
    } else {
      const amount = parseFloat(fromAmount);
      if (isNaN(amount)) {
        newErrors.amount = t("errorAmountInvalid");
      } else if (amount < MIN_AMOUNT) {
        newErrors.amount = `${t("minAmount")}: ${MIN_AMOUNT} FCFA`;
      } else if (amount > MAX_AMOUNT) {
        newErrors.amount = `${t("maxAmount")}:${MAX_AMOUNT} FCFA`;
      }
    }
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error !== "");
  };

  // Single useEffect for auth and user data
  useEffect(() => {
    let mounted = true;

    const initializeComponent = async () => {
      const isValid = await checkAuth();
      if (!isValid && mounted) {
        navigate("/");
        return;
      }

      if (mounted) {
        const TelegramWebApp = window.Telegram?.WebApp;
        if (TelegramWebApp?.initDataUnsafe?.user) {
          setUserData({
            id: TelegramWebApp.initDataUnsafe.user.id,
            first_name: TelegramWebApp.initDataUnsafe.user.first_name,
            last_name: TelegramWebApp.initDataUnsafe.user.last_name,
          });
        } else if (user) {
          setUserData({
            id: parseInt(user.telegramId || "0"),
            first_name: user.fullname.split(" ")[0] || "",
            last_name: user.fullname.split(" ")[1] || "",
          });
        }
      }
    };

    initializeComponent();
    return () => {
      mounted = false;
    };
  }, [checkAuth, navigate, user]);

  useEffect(() => {
    if (process.env.REACT_APP_TEST === "1") {
      setFromAmount(process.env.REACT_APP_FROM_AMOUNT || "");
      setToAmount(process.env.REACT_APP_TO_AMOUNT || "");
      setFromPhone(process.env.REACT_APP_FROM_PHONE || "");
      setToPhone(process.env.REACT_APP_TO_PHONE || "");
    }
  }, []);

  useEffect(() => {
    if (userData) {
      fetchTransactions();
    }
  }, [userData, fetchTransactions]);

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  useEffect(() => {
    const getExchangeRate = async () => {
      const mockRates: { [key: string]: number } = {
        Wave_OrangeMoney: 1.02,
        OrangeMoney_Wave: 0.98,
      };
      const pair = `${fromCurrency}_${toCurrency}`.replace(/\s/g, "");
      setExchangeRate(mockRates[pair] || 1);
    };

    getExchangeRate();
  }, [fromCurrency, toCurrency]);
  useEffect(() => {
    if (transactions.length > 0) {
      const numbers = getUniquePhoneNumbers(transactions);
      setRecentNumbers(numbers);
    }
  }, [transactions]);
  const fetchFees = async (goodId: number, amount: number, userId: number) => {
    try {
      const response = await axios.get(
        `${backendUrlNet}/api/swaps/fee/${goodId}/${amount}/${userId}`
      );
      if (response.status !== 200) {
        throw new Error(`Erreur HTTP : ${response.statusText}`);
      }

      const data = await response.data;
      return data.calculatedAmount;
    } catch (error) {
      console.error("Error fetching fees:", error);
      throw error;
    }
  };

  const handlePhoneSelect = (phone: string) => {
    setFromPhone(phone);
    setToPhone(phone);
  };
  const handleFromPhonechange = async (value: string) => {
    setFromPhone(value);
    setToPhone(value); // Copy the phone number to receiver field

    if (errors.fromPhone) {
      setErrors((prev) => ({ ...prev, fromPhone: "" }));
    }
    if (errors.toPhone) {
      setErrors((prev) => ({ ...prev, toPhone: "" }));
    }
  };

  const handleToPhonechange = async (value: string) => {
    setToPhone(value);
    if (errors.toPhone) {
      setErrors((prev) => ({ ...prev, toPhone: "" }));
    }
  };
  const handleAmountChange = async (value: string) => {
    const numericValue = parseFloat(value) || 0;
    setFromAmount(value);

    if (errors.amount) {
      setErrors((prev) => ({ ...prev, amount: "" }));
    }

    if (numericValue > MAX_AMOUNT) {
      setErrors((prev) => ({
        ...prev,
        amount: `${t("maxAmount")}:${MAX_AMOUNT} FCFA`,
      }));
    }

    const goodId = currencies.find(
      (currency) => currency.caption === selectedCaption1
    )?.id;

    const storedUser = localStorage.getItem("user");
    const userDataFromStorage = storedUser ? JSON.parse(storedUser) : null;

    if (goodId && userDataFromStorage?.telegramId && numericValue > 0) {
      try {
        const fee = await fetchFees(
          goodId,
          numericValue,
          userDataFromStorage.telegramId
        );
        setTransactionFee(fee);
      } catch (error) {
        console.error("Error fetching fees:", error);
      }
    }
  };

  const toggleSupportFraisRetrait = (checked: boolean) => {
    if (checked && !isOnRetrait) {
      setIsOnRetrait(true);
      setIsOnDepot(false);
      let _debit = debit === 1 ? 0 : 1;
      // console.log({ debit, _debit });
      setDebit(_debit);
      if (fromAmount) {
        setToAmount(fromAmount);
      }
    } else if (!checked && isOnRetrait) {
      setIsOnRetrait(false);
      setIsOnDepot(true);
      let _debit = debit === 1 ? 0 : 1;
      // console.log({ debit, _debit });
      setDebit(_debit);
      if (fromAmount) {
        const numericAmount = parseFloat(fromAmount);
        const receiverAmount = Math.max(0, numericAmount + transactionFee); // Application des frais
        setToAmount(receiverAmount.toString());
      }
    }

    // Vérification de la mise à jour après changement
  };

  const toggleSupportFraisDepot = (checked: boolean) => {
    if (checked && !isOnDepot) {
      // Activer le mode Dépôt
      setIsOnDepot(true);
      setIsOnRetrait(false);
      let _debit2 = debit === 1 ? 0 : 1;
      // console.log({ debit, _debit2 });
      setDebit(_debit2);
      //setDebit(0); // Réglez 'debit' à 0 lorsque c'est un dépôt
      if (fromAmount) {
        const numericAmount = parseFloat(fromAmount);
        const receiverAmount = Math.max(0, numericAmount - transactionFee); // Application des frais
        setToAmount(receiverAmount.toString());
      }
    } else if (!checked && isOnDepot) {
      // Désactiver le mode Dépôt et activer le mode Retrait
      setIsOnDepot(false);
      setIsOnRetrait(true);
      let _debit2 = debit === 1 ? 0 : 1;
      // console.log({ debit, _debit2 });
      setDebit(_debit2);
      if (fromAmount) {
        setToAmount(fromAmount); // Retour à la valeur de 'fromAmount' sans modification
      }
    }
  };

  useEffect(() => {
    const numericAmount = parseFloat(fromAmount) || 0;

    if (isOnDepot) {
      const receiverAmount = Math.max(0, numericAmount - transactionFee);
      setToAmount(receiverAmount.toString());
    } else if (isOnRetrait) {
      setToAmount(fromAmount);
    }
  }, [fromAmount, transactionFee, isOnDepot, isOnRetrait]);

  const handleSwap = () => {
    if (!validateInputs()) {
      return;
    }
    setShowSummary(true);
  };
  const handleConfirmSwap = async () => {
    if (!user) {
      console.error("No user data available");
      return;
    }

    setErrors({
      fromPhone: "",
      toPhone: "",
      amount: "",
      selectedCaption1: "",
    });

    setShowSummary(false);
    setIsLoading(true);

    const swapData = {
      phoneNumberRetrait: fromPhone,
      amountRetrait: parseFloat(fromAmount),
      phoneNumberDepot: toPhone,
      user,
      debit: debit,
      good: currencySelected[0],
      compositionorder: [
        {
          goodId: currencies.find(
            (currency) => currency.caption === selectedCaption1
          )?.id,
        },
      ],
      codeGood: currencies.find(
        (currency) => currency.caption === selectedCaption1
      )?.codeGood,
    };

    try {
      const response = await axios.post(`${backendUrlNet}/api/swaps`, swapData);

      const result = await response.data;

      if (response.status !== 200) {
        throw new Error(`Erreur lors du swap! status: ${response.status}`);
      }

      setOrderId(result.orderId);
      setOrderCode(result.orderCode);
      localStorage.setItem("orderId", result.orderId);
      localStorage.setItem("orderCode", result.orderCode);
      setShowPolling(true); // Démarrer le polling

      if (response.status === 200 && result.lienRetrait && result.qrCodeImg) {
        setShowQRDialog(true);
        setLienRetrait(result.lienRetrait);
        setCodeQr(result.qrCodeImg);
      } else if (
        response.status === 200 &&
        !result.lienRetrait &&
        !result.qrCodeImg
      ) {
        setShowDialog(true);
      }

      // Fonction de polling pour surveiller le statut
      const checkTransactionStatus = async () => {
        try {
          const pollingResponse = await axios.get(
            `${backendUrlNet}/api/status/${result.orderId}`
          );
          if (pollingResponse.status !== 200) {
            throw new Error(`Erreur HTTP : ${response.statusText}`);
          }

          const result2 = await pollingResponse.data;

          if (result2.done) {
            await fetchTransactions();

            const { status, message } = result2;
            setStatusTransaction(status);
            setMessage(message);
            setStatusTransaction(status as "success" | "pending" | "failed");
            setMessage(message);

            if (status === "success") {
              clearInterval(pollingInterval); // Arrêter le polling
              setShowPolling(false); // Désactiver le polling
            }
          } else {
            setError(result2.error || "Erreur inconnue");
          }
        } catch (pollingError) {
          setStatusTransaction("failed");
          console.error("Erreur lors du polling:", pollingError);
        }
      };

      // Démarrer le polling toutes les 10 secondes
      const pollingInterval = setInterval(checkTransactionStatus, 2000);

      // Arrêter automatiquement le polling après 2 minutes
      setTimeout(() => {
        clearInterval(pollingInterval);
        setShowPolling(false); // Désactiver le polling
      }, 120000);

      // Réinitialisation des champs
      setFromAmount("");
      setToAmount("");
      setFromPhone("");
      setToPhone("");
      setSelectedCaption11("");
      setSelectedCaption12("");
      setTransactionFee(0);
      await fetchTransactions();
    } catch (error) {
      setShowErrorDialog(true);
      console.error("Erreur lors du swap:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const totalWithFees = isOnRetrait
    ? parseFloat(fromAmount) + transactionFee
    : parseFloat(fromAmount);
  return (
    <TooltipProvider>
      {isAuthLoading ? (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-700 to-pink-600">
          <Loader2 className="h-8 w-8 animate-spin text-white" />
        </div>
      ) : (
        <div className="min-h-screen bg-gradient-to-br from-purple-700 to-pink-600 flex items-center justify-center p-4">
          <div className="w-full max-w-md p-4 mb-16">
            <Card className="w-full mt-8 max-w-md backdrop-blur-lg bg-white/5 border-none shadow-xl relative">
              <div className="absolute -top-12 left-1/2 transform -translate-x-1/2 rounded-none bg-round-red">
                <img src={logo} className="w-[90px] h-[90px]  " alt="Logo" />
              </div>
              <div className="absolute -top-[60px] bottom- left-[45%] -transform -translate-x-1/2">
                <HomeSticker />
              </div>
              <CardHeader className="pt-10">
                <h2 className="text-2xl font-bold text-center text-white">
                  {t("Swap Money")}
                </h2>
              </CardHeader>

              <CardContent className="">
                <FormProps
                  title={t("title1")}
                  currencies={currencies}
                  selectedCaption={selectedCaption11}
                  backendUrlNet={backendUrlNet}
                  amount={fromAmount}
                  phone={fromPhone}
                  transactionFee={transactionFee}
                  errors={errors}
                  fees={isOnRetrait}
                  disabledInput={disabledInputAmount}
                  handleSelect={handleSelect}
                  handleAmountChange={handleAmountChange}
                  setPhone={handleFromPhonechange}
                  toggleSupportFrais={toggleSupportFraisRetrait}
                  placeholder={t("placeholder1")}
                  placeholderNumero={`${t("placeholderPhone2")} `}
                  placeholderMontant={t("placeholderAmount1")}
                  recentNumbers={recentNumbers}
                  selectEmpty={
                    errors.selectedCaption1 && (
                      <p className="text-red-300 text-xs mt-1 bg-red-500/10 px-2 py-1 rounded-md backdrop-blur-sm">
                        {errors.selectedCaption1}
                      </p>
                    )
                  }
                  phoneEmpty={
                    errors.fromPhone && (
                      <p className="text-red-300 text-xs mt-1 bg-red-500/10 px-2 py-1 rounded-md backdrop-blur-sm">
                        {errors.fromPhone}
                      </p>
                    )
                  }
                  amountEmpty={
                    errors.amount && (
                      <p className="text-red-300 text-xs mt-1 bg-red-500/10 px-2 py-1 rounded-md backdrop-blur-sm">
                        {errors.amount}
                      </p>
                    )
                  }
                />

                <div className="flex justify-center">
                  <Button
                    onClick={switchCurrency}
                    variant="ghost"
                    className="mt-5 hover:bg-white/10 rounded-full transition-colors"
                  >
                    <ArrowUpDown className="text-white" />
                  </Button>
                </div>

                <FormProps
                  disabledSelect={disabledSelect}
                  title={t("title2")}
                  currencies={currencies}
                  selectedCaption={selectedCaption12}
                  backendUrlNet={backendUrlNet}
                  amount={toAmount}
                  phone={toPhone}
                  transactionFee={transactionFee}
                  errors={errors}
                  fees={isOnDepot}
                  setPhone={handleToPhonechange}
                  handleAmountChange={handleAmountChange}
                  toggleSupportFrais={toggleSupportFraisDepot}
                  placeholderNumero={`${t("placeholderPhone1")} `}
                  phoneEmpty={
                    errors.toPhone && (
                      <p className="text-red-300 text-xs mt-1 bg-red-500/10 px-2 py-1 rounded-md backdrop-blur-sm">
                        {errors.toPhone}
                      </p>
                    )
                  }
                  placeholderMontant={t("placeholderAmount2")}
                />

                <div className="my-3">
                  <Button
                    onClick={handleSwap}
                    className="w-full py-3 bg-gradient-to-r from-pink-500 to-purple-600 hover:from-purple-600 hover:to-pink-500 text-white font-semibold text-lg rounded-xl shadow-lg transform hover:scale-[1.02] transition-all duration-200 hover:shadow-pink-500/25 relative overflow-hidden group"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center gap-2">
                        <Loader2 className="h-5 w-5 animate-spin" />
                        {t("transactionEncours")}
                      </div>
                    ) : (
                      <div className="flex items-center justify-center gap-2 relative z-10">
                        <span className="tracking-wide">Swap now</span>
                        <span className="animate-pulse">✨</span>
                      </div>
                    )}
                    <div className="absolute inset-0 bg-gradient-to-r from-pink-500/20 to-purple-600/20 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200 origin-left" />
                  </Button>
                </div>

                <ConfirmQrDialog
                  showQRDialog={showQRDialog}
                  setShowQRDialog={setShowQRDialog}
                  lien={lienRetrait}
                  codeQr={codeQr}
                  fromCurrency={fromCurrency}
                />
                <ConfirmDialog
                  showDialog={showDialog}
                  setShowDialog={setShowDialog}
                  fromCurrency={fromCurrency}
                />
                <TransactionSummaryDialog
                  showSummary={showSummary}
                  setShowSummary={setShowSummary}
                  fromCurrency={selectedCaption11}
                  fromPhone={fromPhone}
                  fromAmount={fromAmount}
                  toCurrency={selectedCaption12}
                  toPhone={toPhone}
                  toAmount={toAmount}
                  transactionFee={transactionFee}
                  isOnRetrait={isOnRetrait}
                  totalWithFees={totalWithFees}
                  handleConfirmSwap={handleConfirmSwap}
                />
                <AlertError
                  showQRDialog={showErrorDialog}
                  setShowErrorDialog={setShowErrorDialog}
                />
              </CardContent>
              {showPolling && orderId && (
                <TransactionStatus
                  showPolling={showPolling}
                  orderId={orderId}
                  orderCode={orderCode}
                  statusTransaction={statusTransaction}
                  message={message}
                />
              )}
            </Card>
          </div>

          <div className="fixed bottom-0 left-0 right-0 bg-purple-800 backdrop-blur-sm rounded-t-2xl shadow-lg transition-transform duration-300 max-h-[60vh]">
            <div className="w-full max-w-md mx-auto">
              <div className="flex justify-center py-2">
                <div className="w-12 h-1 bg-white/20 rounded-full" />
              </div>
              <TransactionHistory
                qrCode={"transactions"}
                orders={transactions}
                isLoading={isLoadingTransactions}
                onSelectPhone={handlePhoneSelect}
              />
            </div>
          </div>
        </div>
      )}
    </TooltipProvider>
  );
};

export default SwapInterface;
