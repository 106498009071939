import { Link } from 'lucide-react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from "../../assets/Final Logo/Logo File/Favicon-Icon.png";

const Sidebar: React.FC = () => {
  return (
    <aside className="w-64b bg-purple-900 text-white p-6">
      <div className=" border-spacing-1 mb-4 ml-2 ">
      <img src={logo} className="w-[50px] h-[50px]  " alt="Logo" />

      </div>
      <nav>
        <ul className="space-y-2">
          <li>
            <NavLink
              to={'/support/home'}
              className={({ isActive }) =>
                isActive
                  ? 'block p-2 bg-gradient-to-br from-purple-700 to-pink-600 text-white rounded' // lien actif devient bleu
                  : 'block p-2 hover:bg-purple-700 rounded'
              }
            >
              Tableau de Bord
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/support/claims'}
              className={({ isActive }) =>
                isActive
                  ? 'block p-2 bg-gradient-to-br from-purple-700 to-pink-600 text-white rounded' // lien actif devient bleu
                  : 'block p-2 hover:bg-purple-700 rounded'
              }
            >
              Reclamations
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/support/transactions'}
              className={({ isActive }) =>
                isActive
                  ? 'block p-2 bg-gradient-to-br from-purple-700 to-pink-600 text-white rounded' // lien actif devient bleu
                  : 'block p-2 hover:bg-purple-700 rounded'
              }
            >
              Transactions
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/autres'}
              className={({ isActive }) =>
                isActive
                  ? 'block p-2 bg-gradient-to-br from-purple-700 to-pink-600 text-white rounded' // lien actif devient bleu
                  : 'block p-2 hover:bg-purple-700 rounded'
              }
            >
              Paramètres
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
