import React from "react";
import Lottie from "react-lottie";

interface LottieStickerProps {
  stickerData: object; 
  height: number;
  width: number;
  title?: string;
}

const LottieSticker: React.FC<LottieStickerProps> = ({ stickerData , height , width ,title}) => {
  const defaultOptions = {
    loop: true,
    height: height,
    width: width,
    autoplay: true,
    animationData: stickerData, // Sticker dynamique
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex ">
       <div>{title}</div>
       <div><Lottie options={defaultOptions} height={height} width={width} />
       </div>
    </div>
  );
};

export default LottieSticker;
