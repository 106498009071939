import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="bg-purple-900 shadow p-4 text-white m">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-bold 0"></h1>
        <div className="flex items-center mx-9 mt-2">
          <span className="">Bienvenue, Aminata </span>
          {/* <img
            src="https://via.placeholder.com/40"
            alt="Profile"
            className="w-10 h-10 rounded-full"
          /> */}
        </div>
      </div>
    </header>
  );
};

export default Header;