import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Input } from "./ui/input";
import { Switch } from "./ui/switch";
interface RetraitFormProps {
  currency: string;
  amount: string;
  phone: string;
  currencies: Array<{
    codeGood: string;
    fromCurrency: string;
    imgFromCurrency: string;
  }>;
  TRANSACTION_FEE: number;
  isOnRetrait: boolean;
  errors: { amount?: string; fromPhone?: string };
  setCurrency: (value: string) => void;
  handleAmountChange: (value: string) => void;
  setPhone: (value: string) => void;
  toggleSupportFrais: (checked: boolean) => void;
  backend: string;
  label_zone: string;
}

const SwapForm: React.FC<RetraitFormProps> = ({
  currency,
  amount,
  phone,
  currencies,
  TRANSACTION_FEE,
  isOnRetrait,
  errors,
  setCurrency,
  handleAmountChange,
  setPhone,
  toggleSupportFrais,
  backend,
  label_zone,
}) => (
  <div className="space-y-2">
    <label className="text-[18px] font-medium text-white">{label_zone}</label>
    <div className="flex space-x-2">
      <Select value={currency} onValueChange={setCurrency}>
        <SelectTrigger className="w-full bg-white/20 border-none text-white focus-visible:ring-gray-100">
          <SelectValue placeholder="Select" />
        </SelectTrigger>
        <SelectContent>
          {currencies.map((currency) => (
            <SelectItem key={currency.codeGood} value={currency.fromCurrency}>
              <div className="flex items-center space-x-2">
                <img
                  src={`${backend}${currency.imgFromCurrency}`}
                  alt={currency.fromCurrency}
                  className="w-6 h-6 object-contain rounded-full"
                />
                <span>{currency.fromCurrency}</span>
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Input
        type="number"
        value={amount}
        onChange={(e) => handleAmountChange(e.target.value)}
        className={`sm:w-[200px] w-[120px] bg-white/20 border-none text-white placeholder-white/50 ${
          errors.amount ? "border-red-500 focus:border-red-500" : ""
        }`}
        placeholder="Montant à retirer"
      />
      {errors.amount && (
        <p className="text-red-600 text-xs mt-1">{errors.amount}</p>
      )}
    </div>
    <div className="flex items-center space-x-2">
      <Input
        type="tel"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        className={`flex-1 bg-white/20 border-none text-white placeholder-white/50 ${
          errors.fromPhone ? "border-red-500 focus:border-red-500" : ""
        }`}
        placeholder="Numéro du retrait"
      />
      {errors.fromPhone && (
        <p className="text-red-400 text-xs">{errors.fromPhone}</p>
      )}
    </div>
    <div className="flex items-center justify-between px-2 py-1 bg-white/10 rounded-lg">
      <span className="text-sm text-white">
        Supporte les frais ({TRANSACTION_FEE} FCFA)
      </span>
      <Switch
        checked={isOnRetrait}
        onCheckedChange={toggleSupportFrais}
        className="bg-white/20 data-[state=checked]:bg-white/50"
      />
    </div>
    {isOnRetrait && amount && (
      <div className="text-xs text-white/70 mt-1">
        Total avec frais: {(parseFloat(amount) + TRANSACTION_FEE).toFixed(2)}{" "}
        FCFA
      </div>
    )}
  </div>
);

export default SwapForm;
