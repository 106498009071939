import React from "react";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogTrigger,
  DialogDescription,
} from "./ui/dialog";
import { Button } from "./ui/button";
import { ExternalLink } from "lucide-react";

interface QRDialogProps {
  showQRDialog: boolean;
  setShowQRDialog: (open: boolean) => void;
  lienWave: string;
  codeQr: string;
  title?: string;
  description?: string;
  closeButtonText?: string;
}
const ConfirmationWave: React.FC<QRDialogProps> = ({
  showQRDialog,
  setShowQRDialog,
  lienWave,
  codeQr,
}) => (
  <Dialog open={showQRDialog} onOpenChange={setShowQRDialog}>
<DialogContent className="bg-gradient-to-br from-purple-700/95 to-pink-600/95 border-none text-white">

      <DialogHeader>
        <DialogTitle>Scanner le QR Code Wave</DialogTitle>
        <DialogDescription className="text-white/70">
        Scannez ce QR code avec votre application Wave ou cliquez dessus pour ouvrir directement Wave
        </DialogDescription>
      </DialogHeader>

      <div className="flex flex-col items-center justify-center p-6 bg-white/10 rounded-lg">
        <Link to={lienWave}>
          <img
            src={codeQr}
            alt="QR Code Wave"
            className="w-48 h-48 object-contain"
          />
        </Link>
      </div>

      <div className="flex flex-col gap-2 mt-4">
        <Button
          onClick={() => window.open(lienWave, "_blank")}
          className="bg-white/20 hover:bg-white/30"
        >
          <ExternalLink className="w-4 h-4 mr-2" />
          Ouvrir dans Wave
        </Button>

        <Button
          variant="ghost"
          onClick={() => setShowQRDialog(false)}
          className="hover:bg-white/10"
        >
          Fermer
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

export default ConfirmationWave;




