import React from "react";
import SwapInterface from "./components/SwapInterface";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Assurez-vous d'importer Routes
import { ThemeProvider } from "./components/theme-provider";
import { ThemeSwitcher } from "./components/ThemeSwitcher";

function App() {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="swap-theme-preference">
      <Router>
        <div className="relative min-h-screen bg-gradient-to-br from-[hsl(var(--gradient-start))] to-[hsl(var(--gradient-end))]">
          {/* Position the theme switcher */}
          <div className="absolute top-4 right-4 z-50">
            <ThemeSwitcher />
          </div>

          {/* Utilisez Routes pour définir vos routes */}
          <Routes>
            <Route path="/" element={<SwapInterface />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
