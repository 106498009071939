import React from "react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { ChevronDown, ChevronUp } from "lucide-react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "./ui/collapsible";
import { Transaction } from "@/types/types";

interface TransactionHistoryProps {
  transactions: Transaction[];
  isLoading: boolean;
}

const statusColors = {
  completed: "text-green-500",
  pending: "text-yellow-500",
  failed: "text-red-500",
};

const statusText = {
  completed: "Terminé",
  pending: "En cours",
  failed: "Échoué",
};

const formatDate = (dateString?: string) => {
  if (!dateString) {
    return "Date invalide"; // Retourne un message d'erreur si la date est undefined
  }
  
  const date = new Date(dateString);

  // Vérifie si la date est valide
  if (isNaN(date.getTime())) {
    console.error("Date invalide :", dateString);
    return "Date invalide"; // Retourne un message d'erreur
  }

  const formattedDate = date.toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
  });
  
  return `${formattedDate}, ${formattedTime}`;
};


// Update TransactionHistory.tsx
const TransactionHistory: React.FC<TransactionHistoryProps> = ({
  transactions,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="overflow-hidden"
    >
      <CollapsibleTrigger className="w-full px-4 py-3 flex items-center justify-between text-white hover:bg-white/5">
        <div className="flex items-center gap-2">
          <span className="font-medium text-lg">Transactions Récentes</span>
          <span className="text-xs text-white/70">({transactions.length})</span>
        </div>
        {isOpen ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
      </CollapsibleTrigger>

      <CollapsibleContent>
        <div className="max-h-[50vh] overflow-y-auto px-4 pb-4">
          {transactions.map((transaction) => (
            <div
              key={transaction.id}
              className="p-3 mb-2 bg-white/5 rounded-lg hover:bg-white/10 transition-colors"
            >
              <div className="flex justify-between items-start">
                <div>
                  <div className="flex items-center gap-2">
                    <span>
                      {transaction.fromCurrency} → {transaction.toCurrency}
                    </span>
                    <span
                      className={`text-xs px-2 py-0.5 rounded-full ${
                        statusColors[transaction.status]
                      }/20 ${statusColors[transaction.status]}`}
                    >
                      {statusText[transaction.status]}
                    </span>
                  </div>
                  <div className="text-sm text-white/70 mt-1">
                  {formatDate(transaction.createdAt)}
                  
                  </div>
                  <div className="text-sm mt-1">
                    <span className="text-white/60">De:</span>{" "}
                    {transaction.phoneNumberRetrait}
                    <span className="mx-2">→</span>
                    <span className="text-white/60">Vers:</span>{" "}
                    {transaction.phoneNumberDepot}
                  </div>
                </div>
                <div className="text-right">
                  <div className="font-medium">
                    {transaction.amountRetrait} FCFA
                  </div>
                  <div className="text-sm text-white/70">
                    → {transaction.amountDepot} FCFA
                  </div>
                  <div className="text-xs text-white/60 mt-1">
                    {transaction.debit === 1
                      ? "Frais: destinataire"
                      : "Frais: expéditeur"}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default TransactionHistory;
