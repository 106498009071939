import React from 'react';
import Sidebar from '../../../components/support/Sidebar';
import Header from '../../../components/support/Header';
import Footer from '../../../components/support/Footer';
import { Outlet } from 'react-router-dom';

const Layout: React.FC = () => {
  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar />
      
      {/* Contenu principal */}
      <div className="flex-1 flex flex-col">
        <Header />
        <main className="flex-1 p-6 bg-gradient-to-br from-purple-700 to-pink-600">
          <Outlet /> {/* Contenu de la page active */}
        </main>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Layout;
